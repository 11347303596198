.donutchart-track{
    fill: transparent;

    stroke-width: 20;
  }
  .donutchart-indicator {
    fill: transparent;

    stroke-width: 20;
    stroke-dasharray: 0 10000;
    transition: stroke-dasharray .3s ease;
  }
  
  .donutchart {
    margin: 0 auto;
    border-radius: 50%;
    display: block;
  }
  
  
  .donutchart-text{
    font-family: 'Roboto';
    fill: black;
  }
  .donutchart-text-val{
    font-size:16px;
  }
  .donutchart-text-percent{
    font-size:12px;
  }
  .donutchart-text-label{
    font-size:9px;
  }
  
  