.sidebar {
    min-height: 100vh;
    width: 350px;
	  position: fixed;
	  top: 0px;
	  bottom: 0px;
	  left: 0px;

    -webkit-box-shadow: 0px 0px 20px 5px #000000; 
    box-shadow: 0px 0px 20px 5px #000000;
    background-color: white;


  }

  .smallPicture{
    max-height: 150px;
    border-radius: 50%;
    border: 5px solid white;
    
    opacity: 0;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-tion: 0.5s;
    transition: 0.5s;

    -webkit-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
}

.smallPicture.show{
  opacity: 1;
}

.mainPictureFrame{
padding-top: 10vh;
text-align: center;
visibility: 1;
}

.iconMenuDesk{
  transition: 0.5s;
}

.iconMenuDesk:hover{
  transform: skew(-10deg);
  
}

