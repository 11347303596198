
@media (min-width: 1200px) {
    .mainData {margin-left: 350px;}

}

@media (max-width: 1199px) {
    .mainData {margin-left: 0px;}

}

.mainData{
    float: left;
    clear: both;
    box-sizing: border-box;
}

.mainContent{
    min-height: 100vh;
}