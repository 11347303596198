
.portfolio{


}

@media (min-width: 992px) {
  .portfolio {min-height: 100vh;}
}

@media (max-width: 991px) {
  .portfolio{min-height: calc(100vh - 41px);}
}


.portfolioFlex{
  padding: 4%;
  min-height: 100vh;

}

.portfolioTitle{
  font-family: 'Oswald', sans-serif;
  font-size: 350%;


}

.blackFond{
  background-color: black;
}

.portfolioCenter div
{
  padding: 15px;
  border-radius: 15px;
  min-height: 90%;

}

.portfolioCenter div div{

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


.h-35vh{
  min-height: 35vh;
}



/* Sweep To Right */
.hvr-sweep-to-right {
  min-width: 100%;
  min-height: 100%;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  color: rgba(0, 0, 0, 0);
}
.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(169,169,169, 0.7); 
  border-radius: 15px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  
}
.hvr-sweep-to-right:hover, .hvr-sweep-to-right:focus, .hvr-sweep-to-right:active {
  color: white;
  text-shadow: 2px 2px 1px #000000;



}
.hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}





/* Sweep To Left */
.hvr-sweep-to-left {
  min-width: 100%;
  min-width: 100%;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  color: rgba(0, 0, 0, 0);
}
.hvr-sweep-to-left:before {
  content: "";
  border-radius: 15px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(169,169,169, 0.7); 
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-left:hover, .hvr-sweep-to-left:focus, .hvr-sweep-to-left:active {
  color: white;
  text-shadow: 2px 2px 1px #000000;

}
.hvr-sweep-to-left:hover:before, .hvr-sweep-to-left:focus:before, .hvr-sweep-to-left:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.portfolioText h2{
  font-size: 250%;
}

.portfolioText p{
  font-size: 125%;
}