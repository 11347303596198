
.contact{

}

@media (min-width: 992px) {
  .contact {height: 100vh;}
  .contact{background-color: #F1F1F1;}
}

@media (max-width: 991px) {
  .contact {min-height: calc(100vh - 41px);}
  .contact{background-color: white;}
}


.contactFlex{
  padding: 4%;
  min-height: 100vh;

}

.contactTitle{
  font-family: 'Oswald', sans-serif;
  font-size: 350%;


}

.mailAreaTitle{
  font-size: 150%;
  font-family: 'Oswald', sans-serif;
}

input{
  display:table-cell; 
  width:100%;
}

.g-recaptcha {
  display: inline-block;
  text-align: center;
  overflow: hidden;
  height: auto; opacity: 1;
  
  transition: height 0ms 0.7s, opacity 0.7s 0ms;
}

.g-recaptcha.hide {
  height: 0; opacity: 0;
  transition: height 0ms 0ms, opacity 1s 0ms;
}

.mailButton{
    
  overflow: hidden;
  height: 0; opacity: 0;
  transition: height 0ms 0.7s, opacity 0.7s 0ms;

}

.mailButton.show{
  height: auto; opacity: 1;
  transition: height 0ms 0ms, opacity 1s 0ms;
}

.leaflet-container{
  margin: 2rem;
  height: 60vh;

  -webkit-box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.75);
}

.contactLocation{
  font-family: 'Pacifico', sans-serif;
  font-size: 30%;
  color: black;
  text-shadow: 1px 1px white;
}