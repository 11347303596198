@media (min-width: 992px) {
  .aboutme {min-height: 100vh;}
  .aboutmeText span h1{
    font-size: 300%;
  }
  .aboutmeIco span{

    margin-left: 10px;
    font-family: 'Oswald', cursive;
    font-size: 150%;
  }
}

@media (max-width: 991px) {
  .aboutme{min-height: calc(100vh - 41px);}
  .aboutmeText span h1{
    font-size: 200%;
  } 
  .aboutmeIco span{

    margin-left: 10px;
    font-family: 'Oswald', cursive;
    font-size: 100%;
  }

  .aboutmeTextD{
    font-size: 90%;
  }

}


.aboutmeFlex{
  padding: 4%;
  min-height: 100vh;

}

.aboutmeTitle{
  font-family: 'Oswald', sans-serif;
  font-size: 350%;
}

.aboutmePicture{
  border: 1px solid black;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 70vh;
  -webkit-box-shadow: 10px 10px 0px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 0px 0px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 0px 0px rgba(0,0,0,0.75);

  padding-right: 5%;
}

.aboutmeText{
  padding-left: 5%;
  text-align: justify;
  text-justify: inter-word;
}





.aboutmeText span{

  white-space : nowrap;
  display: inline-block;
}



