.leftMinus{
  left: -1px;
}
.resumeContent div{

  height: 0; opacity: 0;
  transition: height 0ms 0.7s, opacity 0.7s 0ms;

}

.resumeContent div.show{
  height: auto; opacity: 1;
  transition: height 0ms 0ms, opacity 1s 0ms;
}

.expItemRight p {
  margin-top: 5px;
}

.expItemRight {
  position: relative;
  text-align: left;
  border-left: 12px solid #eb4559;
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 40px;
  width: calc(50% + 6px);
  
}
.contact-form .control-group {
  margin-bottom: 20px;
}
.expItemRight h3 {
  color: black;
  font-family: 'Oswald', cursive;
}
.expItemRight h4 {
  color: white;
  font-family: 'Pacifico', sans-serif;
  text-shadow: 2px 2px 2px #000000;
  padding-top: 5px;
  padding-bottom: 5px;
}
.expItemRight h3,
.expItemRight h4 {
  text-shadow: none;
  margin: 0;
  line-height: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.expHolderRight .body:before {
  content: "";
  position: absolute;
  left: 20px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #eb4559 transparent transparent;
  top: 10px;
}



.expHolderRight .body,
.expHolderRight .head {
  padding: 3px 10px;
}
.expHolderRight .head {
  background-color: #eb4559;
}
.expHolderRight .body {
  border: 1px solid black;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  -webkit-border-radius: 0 0 5px 5px;
}
.expHolderRight {
  background-color: white;
  border-radius: 5px;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
  
}

.date-range {
  -webkit-box-shadow: 2px 2px 2px black;
  background-color: white;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 2px 2px 2px black;
  color: black;
  display: inline-block;
  float: right;
  font-size: 17px;
  font-weight: bold;
  padding: 2px 8px;
  margin-top: 5px;
  text-align: center;
}

.date-range {
  font-family: 'Oswald', cursive;
  font-weight: 700!important;
}

.expItemLeft p {
  margin-top: 5px;
}


.expItemLeft {
  position: relative;
  text-align: left;
  border-right: 12px solid #eb4559;
  padding-right: 30px;
  padding-left: 10px;
  padding-bottom: 40px;
  width: calc(50% + 6px );
  
}
.expItemLeft h3 {
  color: black;
  font-family: 'Oswald', cursive;
}
.expItemLeft h4 {
  color: white;
  font-family: 'Pacifico', sans-serif;
  text-shadow: 2px 2px 2px #000000;
  padding-top: 4px;
  padding-bottom: 4px;
}
.expItemLeft h3,
.expItemLeft h4 {
  text-shadow: none;
  margin: 0;
  line-height: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
}


.expHolderLeft .body:before {
  content: "";
  position: absolute;
  left: calc( 100% - 30px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #eb4559;
  
  top: 10px;
}
.expHolderLeft .body,
.expHolderLeft .head {
  padding: 3px 10px;
}
.expHolderLeft .head {
  background-color: #eb4559;
}
.expHolderLeft .body {
  border: 1px solid black;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  -webkit-border-radius: 0 0 5px 5px;
}
.expHolderLeft {
  background-color: white;
  border-radius: 5px;
  -webkit-box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.75);
  box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.75);
  
}

.boule:before {
  background-color: white;
  border: 10px solid #eb4559;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  content: " ";
  height: 20px;
  left: calc( 50% - 10px);
  position: absolute;
  top: -5px;
  width: 20px;

}


.bgBlack{
  background-color: grey;
}

.skillsTitle hr{

  width:25%;
  text-align:center;
  margin-left:37.75%;

}

.skillsTitle h3{

  font-family: 'Pacifico', sans-serif;
  text-align:center;
}


.skillsAlign{
  font-family: 'Oswald', sans-serif;
  font-size: 120%;
  text-align:center;
}

.personalTitle h2{

  font-family: 'Pacifico', sans-serif;
  font-size: 250%;
}

.personalTitle hr{

  width:60%;
  text-align:center;
  margin-left:20%;

}


.personalElem div {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 15px;
  min-height: 25vh;

  color: white;

}

.personalElem div div{
  background: rgba(169,169,169, 0.3);
  height: 100%;
}

.personalElem div div h3{
  font-family: 'Oswald', sans-serif;
  font-size: 280%;
  text-shadow: 2px 2px 2px #000000;
}
