.lienButton{

    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    font-size: 200%;
    padding-left: 10px;



}

.lienButton.show{

    font-size: 250%;
    padding-left: 5px;
    text-shadow: 0px 2px 10px #594B48;
}

.lienButton.clic{

    font-size: 350%;
}


.btmainMenu {
    border-bottom: 2px solid lightgrey;
    transition: all 0.25s linear;
    position: relative;
  }
 
